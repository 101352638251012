<template>
  <div class="container">
    <a href="javascript:history.back()" class="btn btn-primary btn-block">Volver</a>

    <h1 class="text-center mt-2">Términos y Condiciones</h1>
    <h4>Condiciones de uso</h4>
    <p>APP VENTANCAT GRUP, es un servicio online para gestionar diferentes aspectos de un negocio. Las presentes condiciones exponen los derechos y obligaciones del usuario y de APP VENTANCAT GRUP como proveedor del servicio.</p>
    <p>Supondrá que cualquier usuario que utilice el servicio ha leído y aceptado las condiciones de uso.</p>
    <p>CAV VENTANCAT 2010 SL, con NIF B 64505043, domicilio en la calle RAMON MUNTANER 5 LLIÇÀ D´AMUNT 08186 BARCELONA y Registro Mercantil de Barcelona, registro en TOMO 39621, LIBRO 199, HOJA NÚMERO B-348441 proporciona sus servicios relacionados de acuerdo a los términos establecidos a continuación.</p>
    <p>El presente acuerdo tendrá validez a partir de la fecha en la que marque la casilla de aceptación de este acuerdo que aparece en el pie de este documento. sujetas a las Condiciones de uso. En caso de que el
      usuario continúe haciendo uso del Servicio después de cualquier modificación, supondrá su conformidad con dichas modificaciones. </p>
    <h4>1. Condiciones de la cuenta</h4>
    <p>Se deberá proporcionar un nombre legal completo, una dirección de correo electrónico, y toda la información requerida por APP VENTANCAT GRUP en el proceso de creación de la cuenta.</p>
    <p>Es responsabilidad del usuario proporcionar información veraz. APP VENTANCAT GRUP se reserva el derecho de eliminar cualquier cuenta si se sospecha de su veracidad o pudiera incumplir alguna de las normas de uso.</p>
    <p>El usuario es responsable de mantener la privacidad de su cuenta. APP VENTANCAT GRUP no se hace responsable de cualquier daño o pérdida que pueda ser consecuencia de un error del usuario al proteger su información de acceso.</p>
    <p>Las partes aceptan equiparar jurídicamente la firma autógrafa del cliente a la realizada mediante cualquier otro tipo de clave, código o elemento de seguridad identificativo. Sin perjuicio de lo anterior, APP VENTANCAT GRUP podrá exigir, cuando lo considere necesario, confirmación por escrito al cliente.</p>
    <h4>2. Tipos de contrato</h4>
    <p>La contratación de un plan de pago de APP VENTANCAT GRUP se inicia en el momento en el que el usuario introduce los datos de la tarjeta y realiza un primer pago. Los límites de cada plan se especifican en la sección de precios de APP VENTANCAT GRUP.</p>
    <p>APP VENTANCAT GRUP se reserva el derecho de modificar las tarifas unilateralmente y en cualquier momento, sin conceder a dicho cambio ningún derecho de compensación hacia los usuarios.</p>
    <p>3. Pago y acceso</p>
    <p>Para realizar los pagos, es necesario introducir en la cuenta una tarjeta bancaria válida. APP VENTANCAT GRUP cobrará periódicamente al usuario una tarifa recurrente dependiendo del tipo de cuenta contratada.</p>
    <p>El Servicio será cobrado cada período por adelantado y no es reembolsable. No habrá reembolsos o créditos por meses parciales del Servicio, ni habrá reembolsos en caso de que el usuario no utilice el Servicio durante el período de tiempo en que la cuenta esté abierta. En ningún caso se harán excepciones, salvo en los supuestos que se especifican en la política de devoluciones que se encuentra al final de este documento.</p>
    <p>El usuario acepta que no será necesario confirmar la recepción de la aceptación del contrato cuando se haya celebrado exclusivamente mediante comunicación electrónica.</p>
    <p>En caso de impago o devolución del recibo, se notificará al usuario del incumplimiento y perderá automáticamente la capacidad de acceder al Servicio. Los datos se eliminarán en un plazo de treinta (30) días a partir de la fecha de incumplimiento.</p>
    <p>Ninguna tarifa incluye los impuestos o derechos requeridos por las autoridades gubernamentales. El usuario es responsable del pago de estos impuestos u obligaciones. </p>
    <p>El usuario tiene derecho a decidir a quién introducir en su cuenta, con el rol que considere apropiado, si el plan que ha contratado lo permite.</p>
    <h4>4. Modificaciones del servicio y de los planes</h4>
    <p>APP VENTANCAT GRUP se reserva el derecho de modificar o suspender, temporal o permanentemente, el Servicio en cualquier momento y por cualquier motivo si lo considera conveniente, con o sin previo aviso. </p>
    <p>APP VENTANCAT GRUP se reserva el derecho de cambiar las cuotas mensuales con un aviso de 30 días. La notificación de los cambios de cuota se publicará en el sitio web de APP VENTANCAT GRUP y por escrito.</p>
    <h4>5. Cancelación y rescisión</h4>
    <p>El usuario es responsable de la correcta cancelación de su cuenta. La cuenta puede ser cancelada en cualquier momento a través del menú de perfil. </p>
    <p>Si la cuenta es eliminada, que no cancelada, todo su contenido se eliminará después de treinta días. </p>
    <p>El usuario puede cancelar su cuenta en cualquier momento, pero será responsable de todos los cargos realizados hasta ese momento, incluyendo el cargo mensual completo por el mes en el que suspenda el servicio. A partir de entonces, no se le cobrará. </p>
    <p>APP VENTANCAT GRUP se reserva el derecho de cancelar una cuenta o impedir el uso del Software a aquellos que no cumplan con las presentes condiciones de uso.</p>
    <h4>6. Derecho de copia y propiedad intelectual</h4>
    <p>APP VENTANCAT GRUP posee todos los derechos de la Propiedad Intelectual de todos y cualquiera de los componentes del Servicio que puedan protegerse, incluyendo, pero no limitándose, al nombre del Servicio, material gráfico, todo el software asociado con el Servicio y los elementos de la interfaz de usuario contenidos en el Servicio, muchas de las características individuales y la documentación relacionada. </p>
    <p>El usuario se compromete a no copiar, adaptar, reproducir, distribuir, aplicar ingeniería inversa, descompilar o disimular cualquier faceta del Servicio que APP VENTANCAT GRUP posea. </p>
    <p>El usuario también acepta y se obliga a no utilizar robots, spiders y otros aparatos automatizados o procesos manuales para controlar o copiar cualquier contenido del Servicio. </p>
    <p>APP VENTANCAT GRUP no reclamará derechos sobre la Propiedad Intelectual del Contenido que el usuario suba o proporcione al Servicio. Sin embargo, utilizando el Servicio para enviar contenido, el usuario acepta que terceros puedan ver y compartir este contenido enviado.</p>
    <h4>7. Condiciones generales</h4>
    <p>El usuario es completamente responsable del acceso y correcto uso de APP VENTANCAT GRUP con sujeción a la legalidad vigente, sea nacional o internacional, así como a los principios de buena fe, la moral, buenas costumbres y orden público. Y específicamente, adquiere el compromiso de observar diligentemente las presentes Condiciones generales de uso. </p>
    <p>El usuario acepta no revender, duplicar, reproducir o explotar cualquier parte del Servicio sin el expreso consentimiento escrito de APP VENTANCAT GRUP. El usuario no puede usar el Servicio para almacenar, alojar, o enviar correo electrónico dañino o no solicitado (spam). </p>
    <p>El usuario entiende que el Servicio puede utilizarse para la transmisión de su Contenido y que, durante este proceso, su Contenido (incluidas facturas, avisos de pago, y mensajes personales) puede transferirse sin encriptar a través de Internet. El usuario no puede utilizar el servicio para transmitir ningún virus, gusano, o cualquier tipo de contenido malicioso o dañino. </p>
    <p>APP VENTANCAT GRUP no da garantías respecto a la capacidad del usuario al usar el Servicio, su satisfacción con el mismo, que esté disponible en todo momento, ininterrumpidamente y sin errores, la precisión de los cálculos matemáticos llevados a cabo por el Servicio y la corrección de los errores del Servicio. </p>
    <p>Ni APP VENTANCAT GRUP, ni sus socios, ni sus patrocinadores son responsables de cualquier daño directo, indirecto, secundario, consiguiente, especial, ejemplar, punitivo o de cualquier otro tipo que surja o que esté relacionado de cualquier forma con el uso que el usuario haga del Servicio. El usuario solo puede solucionar su insatisfacción con el Servicio dejando de utilizarlo y cancelando su cuenta. </p>
    <p>Si cualquier condición de las aquí descritas queda invalidada o no puede aplicarse, la aplicación de cualquiera de las restantes no debe en ningún caso verse afectada.</p>
    <p>Cualquier pregunta con respecto a las Condiciones de uso deberá dirigirse al soporte técnico de APP VENTANCAT GRUP: ventancat@ventancatgrup.com</p>
    <p>Las Condiciones de uso se regirán por las leyes de España. El usuario y APP VENTANCAT GRUP aceptan someterse a la jurisdicción exclusiva de los tribunales de Barcelona para resolver cualquier cuestión jurídica en relación con las Condiciones.</p>
    <h4>8. Seguridad y privacidad</h4>
    <p>La empresa CAV VENTANCAT 2010 SL se encuentra debidamente inscrita en la Agencia Española de Protección de Datos, cumpliendo así la normativa vigente sobre confidencialidad y seguridad de los datos confidenciales.</p>
    <p>CAV VENTANCAT 2010 SL tienen acceso a los datos de las cuentas con el objetivo de poder mejorar el Servicio, así como de ofrecer un buen soporte. </p>
    <h4>9. Política de devoluciones</h4>
    <p>Suscripciones mensuales: si durante los primeros 30 días utilizando APP VENTANCAT GRUP el usuario no está satisfecho con el servicio, debe contactar a través de ventancat@ventancatgrup, se compromete a encontrar una solución a sus necesidades, y si no fuera posible, se procederá a la devolución del dinero. </p>
    <p>APP VENTANCAT GRUP se compromete a encontrar una solución a sus necesidades, y si no fuera posible, se procederá a la devolución del dinero. Si el usuario no está satisfecho, se le ofrecerá un REEMBOLSO COMPLETO.</p>
    <p>Renovación de las suscripciones: las suscripciones se renuevan automáticamente hasta su cancelación manual. Para cancelar una suscripción, el usuario debe hacerlo a través de la configuración ‘Cuenta’ en su cuenta recuperada. Se realizará un REEMBOLSO COMPLETO por causa de cancelación hasta siete días hábiles después de la fecha de renovación automática.</p>
    <p>Excepción de la política de devoluciones de APP VETANCAT GRUP: no se aceptarán solicitudes de devolución de usuarios a los que se les haya suspendido o resuelto el servicio por el incumplimiento de los términos y condiciones.</p>
  </div>
</template>

<script>
export default {

};
</script>
<style lang="scss">
  @import "@core/scss/vue/pages/page-pricing.scss";
  .container{
    margin-top: 40px;
    padding: 40px;
    text-align: justify;
  }
  h1{
    padding-bottom: 20px;
  }
</style>